import React, {useEffect, useState} from "react";
import moment from 'moment';
import HomeFilter from '../components/SelectFilters/HomeFilter';
import axios from 'axios';
import TotalCallsByType from './Charts/CallsByType';
import './chartsuite.css';
import ChartCard from './ChartCard';

const ChartSuite = (props) => {
  
  
  const [data, setData] = useState([]);
  
  const [filterData, setFilterData] = useState({
    start: moment().set('hour', "0").set("minute", "0").set("second", "0").startOf("year"),
    end: moment().set('hour', "23").set("minute", "59").set("second", "59").endOf("month"),
    selectedChart: "Total Calls",
    house: "Nyton House",
    zones: []
  });

  //get the data on change of the start value = this is a red herring at the momeent as its changed when other values change too needs updating
  useEffect(()=>{
    dbDataRefresh();
  }, [filterData.start]);

  useEffect(()=>{
    dataFiltering();
  }, [data]);

  const handleCallback = (start, end) => {
    setFilterData({
      start: start,
      end: end,
      selectedChart: filterData.selectedChart,
      house: filterData.house,
      zones: filterData.zones
    });
  }

  const label = filterData.start.format('DD/MM/YYYY') + ' - ' + filterData.end.format('DD/MM/YYYY');
  
  const updateSelected = (selectedVal) => {
    setFilterData({ 
      start: filterData.start, 
      end:filterData.end, 
      selectedChart: selectedVal,
      house: filterData.house,
      zones: filterData.zones
    });
  }

  const dataFiltering=()=>{
    let rtnData = [];

    data.map((r)=>{
      let filterPass = false;
      //add in filter options
      filterPass = true;
      if (filterPass) rtnData.push(r);
    });
    
  }

  
  const dbDataRefresh=()=>{

    let sData = {
      "userkey": localStorage.getItem("userEmail"),
      "user": localStorage.getItem("userEmail"),
      "homes": filterData.house,
      "startDate" : filterData.start,
      "endDate" : filterData.end
    };

    axios.post("/reports/data", sData)
      .then(response => {
        const repData = processData(response.data);
        setData(repData);
        localStorage.setItem("GeneralSummaryData", JSON.stringify(repData))
      })
      .catch(e=>{
        console.log("offline data being used");
        const repData = JSON.parse(localStorage.getItem("GeneralSummaryData"));
        setData(repData);
      });
    }


  const homeCallBack = (home) => {
    setFilterData({ 
      start: filterData.start, 
      end:filterData.end, 
      selectedChart: filterData.selectedChart,
      house: home,
      zones: filterData.zones
    });

  }

  return (
    <div id="AIDA_NAV_MAIN">
      <div id="AIDA_NAV" className="AIDA_NAV">

      <p>Home</p>
            <HomeFilter callback={homeCallBack} />

      
      </div>
      <div className="AIDAMAINTCONTENT">
      
        <div className="chartsuite_container">
            <ChartCard title="Call Types">
                <TotalCallsByType data={data} />
            </ChartCard>


        </div>
      </div>

    </div>

  );

}

function calculateCallDuration(duration) {

  const durationArr = duration.split(":");
  let rtnVal = 0;

  if (durationArr.length > 0) {

    const secs = parseInt(durationArr[2]);
    if (secs > 0) rtnVal = secs;

    const mins = parseInt(durationArr[1]);
    if (mins > 0) rtnVal = rtnVal + (mins * 60);

    const hours = parseInt(durationArr[0]);
    if (hours > 0) rtnVal = rtnVal + ((hours*60)*60);


  }
  else {return 0;}
  
  return rtnVal;
}

function processData(data) {

  let rtnVal = [];

  data.map(d=>{
    const callDate = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY");
    const hr = parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY").hour());

  if (d["Call Type"]!="Attendance") {
    rtnVal.push({
      
      "Call Duration" : d["Call Duration"],
      "Call Point" : d["Call Point"],
      "Call Type" : d["Call Type"],
      "Care Delivered" : d["Care Delivered"],
      "Carer ID" : d["Carer ID"],
      "Date" : d["Date"],
      "End Time" : d["End Time"],
      "Resident" : d["Resident"],
      "Start Time" : d["Start Time"],
      "Zone" : d["Zone"],
      "Call Month" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").month()),
      "Call Weekday Number" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").day()),
      "Call Hour" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").hour()),
      "Call Day" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").day()),
      "Call Duration Mins" : calculateCallDuration(d["Call Duration"]),
      "Call Shift" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").hour()),

    });

  }

  });

  return rtnVal;
}


function returDataObj(title, callData) {

  let rtnArr = [];
  
  //rtnArr.push({
//  });

  return rtnArr;
}

export default ChartSuite;