import React from 'react';
import IMG_ACC from '../../images/dashboard/accIcon.png';
import IMG_ALL from '../../images/dashboard/allIcon.png';
import IMG_ASS from '../../images/dashboard/assisIcon.png';
import IMG_ATT from '../../images/dashboard/attenIcon.png';
import IMG_CALL from '../../images/dashboard/callIcon.png';
import IMG_EMG from '../../images/dashboard/emergency.png';
import IMG_CARE from '../../images/care.png';

const DashboardIcon=({callType})=> {

    const cType = callType;
    const cTypeLCase = (callType=="") ? "" : callType.toLowerCase();
    const img = dashImages[cTypeLCase];

    if (cType == "") {return null}

    let height = "50";
    let width = "50";

    const iconStyle = {
        marginLeft: "20px",
        marginRight: "20px"
    }

    return (
        <img 
            alt={callType.toLowerCase()} 
            height={height} width={width} 
            style={iconStyle} 
            src={img} 
        />
    );

}

const dashImages = {
    "accessory": IMG_ACC,
    "all calls": IMG_ALL,
    "assistance": IMG_ASS,
    "attendance": IMG_ATT,
    "call": IMG_CALL,
    "emergency": IMG_EMG,
    "care call": IMG_CARE,
    "carecall": IMG_CARE,
    "visit": IMG_CARE
};

export default DashboardIcon;