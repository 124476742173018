import React, { useEffect , useState} from 'react';
import DTable from './ReactTable';
import './DataTable.css'
import ScrollToTop from '../../scroll_to_top';
export default class DataTable extends React.Component {
  
  state = {
    data: [],
  }
  
  componentDidMount() {
    this.setState({data:this.props.data});
  }

  render() {

    if (this.props.data && this.props.data.length > 0) {
       
      return (<DrawTable hideFilter={this.props.hideFilter} tableData={this.props.data} house={this.props.house} />);
    
    }
    
    return (<div>{this.props.message ? this.props.message : ""}</div>);
    
  }
}


const DrawTable = (props) =>{
 
  const [data, setData] = useState([]);
  
  useEffect(() => {
    setData(props.tableData);    
  }, []);

  

  function search(rows) {
    
    const colums = rows[0] && Object.keys(rows[0]);
    return rows;
    
  }

 
  if (data.length > 0) {
  
    return (<div 
      style={{overflowX:"hidden"}}
    >
      <DTable hideFilter={props.hideFilter} data={search(data)} house={props.house}></DTable>
      <ScrollToTop />

    </div>);
  
  }
  else {

    return (<div>Test</div>);
        
  }

  
}


       