import React from "react";
//import { propTypes } from "react-bootstrap/esm/Image";
import './journeygraph.css';
import JourneyCell from './journeycell';
import FadeIn from '../../FadeIn/FadeIn';
import TearDrop from '../../teardrop/teardrop';
import {ProgressBar} from 'react-bootstrap';


const JourneyGraph = ({calls, showBreakdown}) => {

    /*
    let emCount = 0;
    let assCount = 0;
    let accCount = 0;
    let callCount = 0;
    */
    let total = 0;
    let breakdownRows = [];

    calls.map(m=>{total = total + m.duration});

    if (showBreakdown) { 
        breakdownRows.push(
            
            <ShowBreakdown calls={calls} total={total} />
            
        );
    }

/*
<div>
  <ProgressBar variant="success" now={40} />
  <ProgressBar variant="info" now={20} />
  <ProgressBar variant="warning" now={60} />
  <ProgressBar variant="danger" now={80} />
</div>
*/


    return (
            <div style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <JourneyBarTable>
                                <ProgressBar>
                                    <ProgressBar variant="aq_gray" now={2} key={1} />
                                    <ProgressBar variant="emergency" now={37} key={2} />
                                    <ProgressBar variant="attendance" now={29} key={3} />
                                    <ProgressBar variant="assistance" now={17} key={4} />
                                    <ProgressBar variant="call" now={13} key={5} />
                                    <ProgressBar variant="aq_gray" now={2} key={6} />
                                </ProgressBar>

                    {/*
                        calls.map((m,i)=>{

                            return (
                                <ProgressBar>
                                    <ProgressBar variant="aq_gray" now={2} key={1} />
                                    <ProgressBar variant="emergency" now={37} key={2} />
                                    <ProgressBar variant="attendance" now={29} key={3} />
                                    <ProgressBar variant="assistance" now={17} key={4} />
                                    <ProgressBar variant="call" now={13} key={5} />
                                    <ProgressBar variant="aq_gray" now={2} key={6} />
                                </ProgressBar>
                              );

                            return <GetJourneyCell record={m}
                                        duration={m.duration} 
                                        total={total} 
                                        type={m.type}
                                        />
                            })*/
                            }
                </JourneyBarTable>
                
                {breakdownRows}
            </div>
    );

}


const GetJourneyCell=({duration, total, type, record, cellType})=>{
    const cWidth = (((duration)/total)*100);
    const cColor = colours[type];
    
    return <JourneyCell 
                        record={record}
                        color={cColor}
                        width={cWidth}
                        type={type}
                         />;
}


export const ShowBreakdown=({calls, total})=>{

    let rtn = [];
    let tmp = [];
    let tCount = 0;
    let lastCarer = "";
    let carerName = "";
    let carerID = "";


    calls.map((m,i)=>{
        
        let rtnPush = false;

        if (typeof(m.carer)=="object") {
            
            if (m.carer.name != lastCarer) {
            
                carerName = m.carer.name;
                carerID = m.carer.cid;

            }
        
        }
        

        if ((m.type=="Call")||(m.type=="Assistance")||(m.type=="Emergency")) {
            rtnPush = true;    
        }

        if ((rtnPush) && (tmp.length > 0)) {
            const w = ((tCount/total)*100);
            rtn.push(<JourneyBarTable 
                                        carerName={carerName}
                                        carerID={carerID}
                                        width={w} >{tmp}</JourneyBarTable>);
            tmp = [];
            tCount = 0;
        }

        tCount = tCount + m.duration;
        tmp.push(
            <GetJourneyCell record={m}
                            duration={m.duration}
                            total={total}
                            type={m.type}
                    />
        );

    });



    if (tmp.length > 0) {  
        
        const w = ((tCount/total)*100);
        rtn.push(<JourneyBarTable   
                                    carerName=""
                                    carerID=""
                                    width={w}>{tmp}</JourneyBarTable>);
    
    }

    return rtn;
}

const JourneyBarTable = (props) => {
    
    const tWidth = props.width ? props.width + "%" : "100%";

    const tStyle = {
        width: tWidth,
        borderSpacing: "0px",
        height:"1em",
        zIndex:"100"
        //marginBottom:"1em"
    }
    
    const GetTearDrop=(p)=>{
        
        if (typeof(p.carerName)=="undefined") return null;

        if (p.carerName!="") {
            let dv = (<span>{p.carerName} {p.carerID}</span>);
            return <TearDrop content={dv} width={p.width} />;    
        }
        
        return null;
    } 
    
    return(
        <FadeIn>
            
            <GetTearDrop carerName={props.carerName} width={props.width} />

            <table style={tStyle}>
                <tr className="journey_bar_main">
                {props.children}
                </tr>
            </table>

       </FadeIn>
    );
}



const colours = {
    Call: "rgb(245, 129, 78)",
    Emergency : "red",
    Assistance : "yellow",
    Attendance : "rgb(148, 202, 102)",
    end: "gray"
  }



export default JourneyGraph;