import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';


export default class TotalCallsByType extends PureComponent {
   
  render() {
 
    const startData = this.props.data;
    //let data = datas;    
    let data = [];

    const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})

    let groupedData = groupByKey(startData, 'Call Type', {omitKey:true});

    Object.keys(groupedData).map(k=>{
      data.push({
        name: k,
        counter: groupedData[k].length 
      })
    });
    
    return (
      <ResponsiveContainer width="100%" height="70%">
        <BarChart width={150} height={40} data={data}>
          <Tooltip />
          <XAxis dataKey="name" stroke="#8884d8">
           {//} <Label value="Call Types" offset={0} position="insideBottom" />
  }
          </XAxis>
          {//<YAxis />
  }
          <Bar 
            dataKey="counter" 
            fill="#8884d8" 
           //label={renderCustomBarLabel}
            />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${value}`}</text>;
};