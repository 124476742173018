import React, {useContext,useState, useEffect} from 'react';
import './splashcollect.css';
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';
import axios from 'axios';
import MainMenu from './MainMenu';
import { decryptAndParse } from '../../encrypt/encryptionHandler';
import AuthContext from '../../AuthContextProvider';
import getLocalSetting from '../get_local_setting';

const SplashCollect = () => {

  const ctx = useContext(AuthContext);

  const [userObject, setUserObject] = useState(null);
  const [requiredFields, setRequiredFields] = useState({
    fields:["sites"]
  });


  useEffect(() => {
    
    let sites = getLocalSetting("sites");
    
    if (getLocalSetting("sites")==null) {
      console.log("SIIIIIITESSSSSSSSSSSSS");
      updateDBValues("/user/sites", "sites");
    }
    
    updateDBValues("/user/carers", "carers");
    setPermissions("/user/permissions");

    checkValidServiceWorker();
    getUserObject();  

  },[]);


  function getUserObject(){
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail")
    };

    axios.post('/user/user_object', sData)
    .then(response => {
        const r = response.data;
        localStorage.setItem("user_object", JSON.stringify(r));
        const decryptObj = JSON.parse(decryptAndParse(r));
        
        ctx.user = decryptObj.user;
        ctx.sites = decryptObj.site;
        ctx.permissions = decryptObj.permissions;
        ctx.sitePanels = decryptObj.site_panels;
        
        setUserObject(decryptObj);
    })
    .catch(e=>{ console.log("Error", e);});
  }
  


  
    function updateCompleteFields(updatedField){
        let tmpArr = requiredFields.fields;
        let fieldIndex = tmpArr.indexOf(updatedField);
        if (fieldIndex > -1) {tmpArr.splice(fieldIndex, 1);}       //remove from required array if updated

        setRequiredFields({fields:tmpArr});
    }

    function setPermissions(path){
        let sData = {
          "user": localStorage.getItem("userEmail"),
          "userkey": localStorage.getItem("userEmail")
        };
        axios.post(path, sData)
        .then(response => {
          let r = decryptAndParse(response.data);
          if ((r == "")||(r == null)) {r = "[]"}
          if (Array.isArray(r)) {
            r.map(pm=>{
              localStorage.setItem(pm.pm_name, true)
            });
          }
          updateCompleteFields("permissions");
        }).catch(response=>{

        });
    }

    

      function updateDBValues(path, updateItem) {
        localStorage.setItem("userKey", localStorage.getItem("userEmail"));
        let sData = {
          "user": localStorage.getItem("userEmail"),
          "userkey": localStorage.getItem("userEmail")
        };
        axios.post(path, sData  
        )
        .then(response => {
          let r = JSON.stringify(response.data);
          if ((r == "")||(r == null)) {r = "[]"}
          localStorage.setItem(updateItem, r);
          updateCompleteFields(updateItem);
        });
      }

      if (userObject) return <MainMenu USER={userObject} />;
      return (<div style={logoStyle}><img className="splashFading" src={IMG_AIDA_LOGO} /></div>);
    
 
} 

const logoStyle = {
  margin: "auto",
  width: "100%",
  paddingTop: "10%",
};

function checkValidServiceWorker() {
    let workerEnabled = false;
    if (navigator.serviceWorker) {workerEnabled = true;}
    sendServiceWorkerStatus(workerEnabled);
}


function sendServiceWorkerStatus(status){
    try {
        console.log("sendServiceWorkerStatus");
        let sData = {
            "user": localStorage.getItem("userEmail"),
            "userkey": localStorage.getItem("userEmail"),
            "workerservicestatus": status
        };
        axios.post("/user/workerservicestatus", sData)
        .then(response => {})
        .catch(response=>{

        });
    } catch (e){console.log(e);}
}


export default SplashCollect;

