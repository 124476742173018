import React, {useRef, useContext, useEffect} from 'react';
import LiveCall from './LiveCall';
import DashBoardCallContainer from '../DashBoardCallContainer/DashBoardCallContainer';
import './LiveCall.css';
import firebase from 'firebase'
import Moment from 'moment';
import getLocalSetting from '../../../components/get_local_setting';

export default class LiveCallController extends React.Component {

    state = ({
        selectedIndex: 0,
        data: [],
    });

    updateSelectedIndex(index){
        this.setState({selectedIndex: index});
    }
    
    getDateRef = (ref) => Moment(ref).format('DDMMYYYY');
    
    getData(ref, dateVal) {
        console.log("ref update", ref);
        let liveCalls = {};

        //ref =  "adcPhoneLiveCalls";
        
        console.log("refLive", ref);

        firebase.database().ref(ref).on("value", snapshot => {
            console.log("presenap", snapshot.val())   
            snapshot.forEach(snap => {
                
                const r = snap.val();
                let tmpRefVal = r.zone + "_" + r.room;
                
                const fld = {
                    "Date" : dateVal,
                    "Zone" : r.zone,
                    "Resident" : "",
                    "Call Point" : r.room,
                    "Call Type" : r.callType,
                    "Start Time" : r.start,
                    "End Time" : r.end,
                    "Call Duration" : r.duration,
                    "Clear Type" : (r.end==="Live") ? "Live" : "Reporting", 
                    "Carer" : r.carer,
                    "Care Delivered" : r["Care Delivered"] ? r["Care Delivered"] : [],
                    "reason" : r["reason"] ? r["reason"] : "",
                    "comments" : r["comments"] ? r["comments"] : "",
                };
                Object.assign(liveCalls, {[tmpRefVal]: fld});
                    
            });

            let tmpCalls = [];
            
            Object.keys(liveCalls).map((key) => {
                if (liveCalls[key]["Clear Type"]==="Live") {
                    tmpCalls.push(liveCalls[key]);
                }
            });
            
            this.setState({data: tmpCalls});

          });

    }
    
    getLiveCallsList(calls){
        const rtnVAl = calls.map((c, i)=>{
                console.log({c});    
                return(   
                    <div
                        style={{width:"100%"}}
                        key={i}
                        onClick={()=>this.updateSelectedIndex(i)}>
                         <LiveCall
                                key={i}
                                index={i}
                                callType={c["Call Type"]}
                                room={c["Call Point"]}
                                zone={c.Zone}
                                carer={c.Carer}
                                careDelivered={c["Care Delivered"]}
                                className={(this.state.selectedIndex === i) ? "selected_live_call" : "live_call"}
                                selectedIndex={this.state.selectedIndex} 
                                startDate={c["Date"]}
                                startTime={c["Start Time"]}
                                reason={c["reason"]}
                                comments={c["comments"]}
                        />
                    </div>
                    
                );
                
            })
    
    
        return rtnVAl;
    }

    componentDidMount() {
        let site = JSON.parse(getLocalSetting("sites"));
        let ref = "";
        if (Array.isArray(site)) {
            if (site.length > 0) {
                ref = site[0].value;
            }
        }

        ref = ref + "/" + this.getDateRef(new Date());
        this.getData(ref, this.getDateRef(new Date()));    
    
        if (this.props.sitePanels && (this.props.sitePanels.length>0)) {
            this.props.sitePanels.map(p=>{
                if (p.visible === 1) {
                    console.log("sitepanel add")
                    if (p.panel_ref!==site[0].value) this.getData(p.panel_ref, this.getDateRef(new Date()));
                }
            });
        }

    }


    render() {

        let calls = this.state.data ? this.state.data : [];
        let callsRender = this.getLiveCallsList(calls);
/* dashboard_grid_item_two_row */
        return(
            
            <div className='dashboard_grid_item arquella_scheme_primary '>
                <div className='dashboard_title'>Live Calls</div>                    
                {(calls.length>0) ? callsRender : null}
            </div>

        );

    }

}
