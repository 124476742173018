import React from 'react';

const MenuItem = (props) => {
    let care = localStorage.getItem("care");
    let cName = "menu_item_card";
    
    if (props.disabled) cName = cName + " disabled";

    return(
    <div className={cName} >
      <span>{props.menuname}</span>
      <img alt={props.menuname} src={props.imgPath} />
    </div>
    );
  }
  
  export default MenuItem;