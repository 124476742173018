import crypto from 'crypto';

const secret = 'rcsmK!M1OY40RZiR)CMYUFm1h2XBVfmN';

export const encrypt = (text) => {
    const iv = Buffer.from(crypto.randomBytes(16));
    const cipher = crypto.createCipheriv("aes-256-ctr", Buffer.from(secret), iv);
  
    const encryptedText = Buffer.concat([
      cipher.update(text),
      cipher.final(),
    ]);
    return {
      iv: iv.toString("hex"),
      text: encryptedText.toString("hex"),
    };
  };
  
export const decrypt = (encryption) => {
  if (encryption === null) return null;
  //if (typeof(encryption) !== "object") return null;
    //if (!("iv" in encryption)) return null;
    const decipher = crypto.createDecipheriv(
      "aes-256-ctr",
      Buffer.from(secret),
      Buffer.from(encryption.iv, "hex")
    );
  
    const decryptedText = Buffer.concat([
      decipher.update(Buffer.from(encryption.text, "hex")),
      decipher.final(),
    ]);
  
    return decryptedText.toString();
  };

export const decryptAndParse = (content) => JSON.parse(decrypt(content));