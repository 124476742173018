import React, {useEffect, useRef} from 'react';
import firebase from 'firebase'
import Moment from 'moment';
import * as d3 from "d3";
import { useState } from 'react';
import './phone.css';
import IMG_PHONE from  '../../images/resident_summary/calldash.png';

const PhoneBatteryLevels=({selectedHome, selectedDate})=>{

    const [data, setData] = useState([{name: "Staff Phone 1", level:"5"}]);
    const getDateRef = (ref) => Moment(ref).format('DDMMYYYY');
    
    function getData(ref) {

        
        firebase.database().ref(ref).on("value", snapshot => {
            
            let tmpArray = [];
            snapshot.forEach(snap => {
                
                const r = snap.val();
                tmpArray.push(r);
                
            });

            if (tmpArray.length>0) {
                setData([tmpArray[tmpArray.length-1]]);
            }
            console.log({tmpArray});
            
          });
          
    }
    
    useEffect(()=>{

        getData("lindseyhall_site/events/Phone/2022_8_12");
//        setData([{name: "Staff Phone 1", level:(data[0].level), "Charging":false}]);
    
    },[]);
/*
    useEffect(()=>{
        
        if (data[0].level == 0) {
            setData([{name: "Staff Phone 1", level: 1, Charging: true}]);
            return;
        }

        if ((data[0].level == 100)) {
            setData([{name: "Staff Phone 1", level: 99, Charging: false}]);
            return;
        }   

        let tlevel = 0
            
        if (data[0].Charging==true) {
            tlevel = data[0].level+1;
        }
        else {
            tlevel = data[0].level-1;
        }

        let chg = data[0].Charging;

        setTimeout(()=>{
            setData([{name: "Staff Phone 1", level: tlevel, Charging: chg}]);
        }, 100);
        
    },[data]);
*/
    return(
        <div>
            {data.map(d=>{return(
                <div className='phone_battery_container'>
                    <img className="battery_level_phone_img" src={IMG_PHONE} />
                    <div className='phone_battery_name'>{d.user}</div>
                    <PhoneBatteryChart title={d.name} level={d.level} charging={d.Charging} /> 
                </div>
            );})}
        </div>
    );

}


const PhoneBatteryChart = ({title, level, charging}) => {
   
    let className = "phone_battery_level " 
    
    if (charging==true) {
        className = className + " phone_battery_charging";
    } 
    else {

        if (level < 10)  {
            className = className + " phone_battery_low";
        } 
        else {
            className = className + " battery_level_phone_img";
        }

    }

    const style = {
        width: level + "%"
    }

    return (<div className="phone_battery_guage"><div style={style} className={className}></div></div>);

}

  const PhoneBatteryChartOld = ({title, level, charging}) => {
    const gRef = useRef(null);
    useEffect(()=>{
    
        const levelColour = (charging==true) ? "orange" : (level>15) ? "#4cc1bd" : "red";

        gRef.current.innerHTML = "";
    
        const barchart_width = 200;
        const barchart_height = 30;
      
        var svg = d3.select(gRef.current)
              .append("svg")
              .attr("width", barchart_width)
              .attr("height", barchart_height);
        
        svg.append("rect")
            .attr("fill", "white")
            .style("x", 0)
            .style("y", 0)
            .style("height", barchart_height)
            .style("width", barchart_width)
            .attr("rx", 5)
            .attr("stroke", "#4cc1bd")
            .attr('stroke-width', '5');
    

        svg.append("rect")
            .attr("fill", levelColour)
            .style("x", 0)
            .style("y", 0)
            .style("height", barchart_height)
            .style("width", (level*2))
            .attr("stroke", "#4cc1bd")
            .attr('stroke-width', '5');
    
        let textX = (level*2)-20;
    
        if (textX >= 15) {
            
            svg.append("text")
                .text(level)
                .attr("x", textX)
                .attr("y", barchart_height/2 +5)
                .attr("font-size", "16")
                .attr("text-anchor", "middle")
                .attr("fill", "white");
        
        }

    },[level]);
    return (<div ref={gRef} className="phone_batter_container"></div>);
  }
export default PhoneBatteryLevels;