import React from 'react';
import IMG_Print from '../images/icons/print_white.png';
import createWordDoc from './Export/WordExport';
import ExcelDoc from './Export/ExcelExport';
import ToPDF from './Export/PDFExport';
import { Dropdown } from 'react-bootstrap';

const PrintOptions=({wordOBJ, excelOBJ, pdfOBJ, data, title})=>{

    const OBJ_Print = (Array.isArray(data)) ? buildPrintObject("Call Data", data) : [];
    
    if ((Array.isArray(data)) && (data.length===0)) return(<div></div>);
    
    console.log({OBJ_Print});

    return(
    <Dropdown>
    
        <Dropdown.Toggle id="dropdown-autoclose-true">
            <img width="25" src={IMG_Print} />
        </Dropdown.Toggle>

        <Dropdown.Menu>

        <Dropdown.Item >
            <div id="sel_export_word"
                onClick={()=>{
                createWordDoc((wordOBJ) ? wordOBJ : OBJ_Print, "Arquella Call Report", "Showing all calls");
            }}>Word</div>
        </Dropdown.Item>

      {
        (excelOBJ!==undefined)
        ?
        <Dropdown.Item>
            <ExcelDoc data={(excelOBJ===null) ? buildPrintObjectNoTitle(data) : excelOBJ} />
        </Dropdown.Item>
        :null
      }
        {
            (pdfOBJ!==undefined) 
            ?
            <Dropdown.Item >
                <ToPDF printObj={(pdfOBJ===null) ? OBJ_Print : pdfOBJ} />
            </Dropdown.Item>
            : null    
        }
        
     
    </Dropdown.Menu>

    </Dropdown>

    );

}

const buildPrintObjectNoTitle = (data) => { return { CallList: data } }

    const buildPrintObject = (title, data) => {
        return {
                    title: title,
                    CallList: data,
                }
    }

export default PrintOptions;