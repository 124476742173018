import React, {useContext} from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import IMG_DEFAULT_USER_IMAGE from '../../images/user.png';
import AuthContext from '../../AuthContextProvider';


export default function NavUserMenu({signOut, USER}){
  let imgUser = IMG_DEFAULT_USER_IMAGE;
  return <ShowUserMenu signOut={signOut} imgUser={imgUser} USER={USER} />
}



function ShowUserMenu({signOut, imgUser, USER}){
  const ctx = useContext(AuthContext);

  return(
    <Menu as="div" className="ml-3 relative">
    <div>
      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        
        <img
          className="h-8 w-8 rounded-full"
          src={imgUser}
          alt=""
        />
        
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
      {console.log("CTXUSER", ctx)}
        {
        (ctx.user[0].Level>=90)
        && 
        <>
          <Menu.Item><a href="multidash">Engineers Survey Dashboard</a></Menu.Item>
          <br />
          <Menu.Item><a href="UserSiteManagement">User Site Management</a></Menu.Item>
        </>
        }
        {(ctx.user[0].Level>=91) ?? <OnboardingOption />}

        <Menu.Item>
          {({ active }) => (
            <div
              className={(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
              onClick={()=>signOut()}
            >
              Sign out
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
  );
}

const OnboardingOption=()=>{
  return (<Menu.Item>
    {({ active }) => (
      <>
      {/*}
      <a
        href="onboarding"
        className={(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
      >
        Onboarding
      </a>
      */}
      {/*
      <a
        href="UserSiteManagement"
        className={(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
      >
        User Site Management
      </a>
    */}
      </>
    )}
  </Menu.Item>
  );
}