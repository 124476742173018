import axios from 'axios';

class IIWARI_CONNECTOR {

    iaxios = axios.create({
        baseURL: 'https://dash.iiwari.cloud/api/v1/',
        timeout: 1000,
        //headers: {'X-Custom-Header': 'foobar'}
    });

    mainUrl = "https://dash.iiwari.cloud/api/v1/";
    data = { 
      'email': 'andrewchapman@arquella.co.uk', 
      'password': 'HWvxRdFBEAWS5Ofl',
      'dash_session': 'c75MeX85QNzlxTRpaxRWj8atnuOUc4gvUwItsTQCQaf8nnQUoxwKTjXyg2BAYNnzkBnPij8YutJ0jlkZDD'
    };
    
    postAPIData=(url, submitData)=>{
      return new Promise((resolve, reject)=>{
        try {
        
          submitData = Object.assign(submitData, this.data);
          const toRun = this.mainUrl + url;
          this.iaxios
            .post(toRun, submitData)
            .then(res =>resolve(res))
            .catch(error =>reject(error));
        }
        catch(e){reject(e)}
      });
    }
    
    objectToQueryString(obj){
      let queryString = "?"
      Object.keys(obj).map((o,i)=>{
        if (i>0) queryString+="&"
        queryString+=`${o}=${obj[o]}`
      });
      return queryString;
    }
  
    getAPIData=(url, submitData)=>{
      return new Promise((resolve, reject)=>{
        try {
          const toRun = this.mainUrl + url;
          this.iaxios
          .get(toRun + this.objectToQueryString(submitData))
          .then(res =>resolve(res))
          .catch(error =>reject(error));
        }
        catch(e){reject(e)}
      });
    }
  }

export default IIWARI_CONNECTOR;