export const getAverageTime = (arrOfObj, avField) => {
    let count = arrOfObj.length;
    let totalSecs = 0;

    arrOfObj.map(o=>{

        let fldVal = o[avField];
        if (!isNaN(fldVal)&&(fldVal!=null)) totalSecs += parseInt(fldVal);

    });

    const avr = totalSecs/count;
    const roundUp = Math.round(avr);
    const rtn = convertSecsToTime(roundUp);
    
    return rtn;
}

export const convertSecsToTime = (secs) => {
    let rtn = "";
    if ((!isNaN(secs))&&(secs!=null)) {
        try {
            let dt = new Date(null);
            dt.setSeconds(secs);
            rtn = dt.toISOString().substr(11, 8);
        } catch(err){
            return "ERR";
        }
    }
    else return "00:00:00";
    return rtn;
}