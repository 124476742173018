import React, { useState } from 'react';
import DropSelect from '../../components/common/DropSelect/DropSelect';
import getLocalSetting from '../get_local_setting';

const HomeFilter = (props) => {

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: props.homeOptions ? props.homeOptions : JSON.parse(getLocalSetting('sites')),
        homeSelection: props.homeSelection ? props.homeSelection : JSON.parse(getLocalSetting('sites'))[0].label,
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }
    
    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default HomeFilter;