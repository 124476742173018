import React, {useState, useEffect, useRef, useContext} from 'react';
import { useRowState } from 'react-table';
import firebase from 'firebase';


const EditUserPage = ({user, callBackFunction}) =>{

    const [firstName, setFirstName] = useState(user.firstName);
    const [surname, setSurname] = useState(user.surname);
    const [passCode, setPassCode] = useState(user.pCode);

    function closeEvent(){ callBackFunction(null);}
    

    function handleChange(change, event) {
        const index = event.target.id;
        const indexVal = event.target.value;

        if (index=="firstName") setFirstName(indexVal);
        if (index=="surname") setSurname(indexVal);
        if (index=="passCode") setPassCode(indexVal);
        console.log(index);
        console.log(indexVal);
    }

    function submit(){

        let updateOBJ = {                
            firstName: firstName,
            surname: surname,
            passCode: passCode
        };
        console.log({updateOBJ})
        firebase.database().ref("adc_site/staff").push().set(updateOBJ);
    }

    return(
        <dialog open>
            Edit User
            <div>
                <div>
                    <div>First name</div>
                    <div><input id="firstName" onChange={handleChange.bind(this, 'firstName')} /></div>
                </div>
                <div>
                    <div>Surname</div>
                    <div><input id="surname" onChange={handleChange.bind(this, 'surname')} /></div>
                </div>
                <div>
                    <div>Pass Code</div>
                    <div><input id="passCode" onChange={handleChange.bind(this, 'passCode')} /></div>
                </div>
            </div>
            <button
                onClick={()=>submit()}
            >Add User</button>
        </dialog>
    );
}

export default EditUserPage;