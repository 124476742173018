import React, { useEffect, useState } from "react";
import PrimaryButton from "./Buttons/primary_button";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const scrollStyle = {
    position: "fixed",
    bottom: "0.5rem",
      right: "0.5rem",
      animation: "fadeIn 700ms ease-in-out 1s both",
      cursor: "pointer",
    
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div style={scrollStyle}>
      {isVisible && 
        <div onClick={scrollToTop}>
          <PrimaryButton>Back to top</PrimaryButton>
        </div>}
    </div>
  );
}