import {encrypt, decrypt} from '../encrypt/encryptionHandler';

const getLocalSetting=(setting_name)=>{
console.log({setting_name})
    try {
        return decrypt(JSON.parse(localStorage.getItem(setting_name)));
    }
    catch(e){
        return JSON.parse(localStorage.getItem(setting_name));
    }
}

export default getLocalSetting;