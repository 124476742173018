import React, {useState, useEffect, useContext} from 'react';
import Moment from 'moment';
import './Dashboard.css';
import firebase from 'firebase';
import DashboardSummaryContainer from './DashSummaryContainer';
import 'bootstrap/dist/css/bootstrap.min.css'
import cleanData from '../../components/ADCChartSelector/CleanData';
import getLocalSetting from '../../components/get_local_setting';
import { getAverageTime} from '../../components/common/time_helper';


const CompletedCalls = ({homeSelection, dateSelection, printCallBack, USER}) => {

    const [callsList, setCallsList] = useState([]);
    const [calls, setCalls] = useState({});
    const [callsUpdated, setCallsUpdated] = useState(new Date());

    const getDateRef = (ref) => {
        let options = getDateSelectionList();
        let n = "";
        options.map((o)=>{
            if (o.label === ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    function setUpListeners(){
        let dt = getDateRef(dateSelection);
        console.log("setup listeners");
        console.log({dt});
        console.log(getDateRefDisplay(dt));
        console.log({dateSelection});
        console.log({homeSelection});
        homeSelection.map(h=>{
            console.log({h});
            const ref = h.value + "/" + dt;
            console.log({ref});
            getfb(h.label, dt, h.label, ref);    
        });
        //let ref = house + "/" + getDateRef(rDate);
        //callListeners.push(getfb(house, rDate, houseTitle, ref));          
    }
    
    function getTimeFromLongDate(longDate){
               
        if (longDate===undefined) return "";
        if (longDate.length>10) {
            longDate = longDate.split(" ")[1];
            longDate = longDate.split(".")[0];
        }
        return longDate;
    }
    

    function addCallSnapshot(snapshot, house, rDate, houseTitle){
        console.log(house);
        console.log(rDate);
        console.log(houseTitle);
        
        if (!Object.keys(calls).includes(house)) { Object.assign(calls, {[house]:[]}) }

        const callList = calls[house];
        
        snapshot.forEach(snap => {
            const k = snap.key;
            const r = snap.val();
            console.log({k});
            if (r.callType=="Visit") return;
            callList.push(
                {
                    //"CallID": r["aqRef"] ? r["aqRef"] : (r.start + "_" + r.room),
                    "House": houseTitle,
                    "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                    "Zone" : r.zone,
                    "Resident" : "",
                    "Call Point" : r.room,
                    "Call Type" : r.callType,
                    "Start Time" : getTimeFromLongDate((r.startFullDate!==undefined) ? r.startFullDate : r.start),
                    "End Time" : r.end,
                    "Call Duration" : r.duration,
                    "Carer ID" : r.carer,
                    "Care Delivered" : r["Care Delivered"]  
                }
            );
        });
        
        console.log("OBBBBBJKJJECT");
        console.log(calls);
        setCallsUpdated(new Date());


        
    }
    
    
    const getfb = (house, rDate, houseTitle, ref)=> new firebase.database().ref(ref).on("value", snapshot => addCallSnapshot(snapshot, house, rDate, houseTitle));



    useEffect(()=>{
        setUpListeners();
    },[homeSelection]);
    
    return ( 

        <div>
            <div>
                <div>Sumary</div>
                <div>
                {
                Object.keys(calls).map(site=>{
                    return( <div>
                                <div>{site}</div>
                                <div>{calls[site].length}
                                    {/*
                                    calls[site].map(c=>{
                                        return (<div>{
                                                Object.keys(c).map(k=>{
                                                    return(<div><b>{k}: </b>{c[k]}</div>)
                                                })
                                            }</div>);
                                    })
                                */}
                                </div>
                            </div>)
                })
                }

                </div>
            </div>
            
        </div>
    );

};




const CompletedCallssas = ({homeSelection, dateSelection, printCallBack, USER}) => {

    const [calls, setCalls] = useState({});
    const [callsUpdated, setCallsUpdated] = useState(new Date());

    const getDateRef = (ref) => {
        let options = getDateSelectionList();
        let n = "";
        options.map((o)=>{
            if (o.label === ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    function setUpListeners(){
        let dt = getDateRef(dateSelection);
        console.log("setup listeners");
        console.log({dt});
        console.log(getDateRefDisplay(dt));
        console.log({dateSelection});
        console.log({homeSelection});
        homeSelection.map(h=>{
            console.log({h});
            const ref = h.value + "/" + dt;
            console.log({ref});
            getfb(h.label, dt, h.label, ref);    
        });
        //let ref = house + "/" + getDateRef(rDate);
        //callListeners.push(getfb(house, rDate, houseTitle, ref));          
    }
    
    function getTimeFromLongDate(longDate){
               
        if (longDate===undefined) return "";
        if (longDate.length>10) {
            longDate = longDate.split(" ")[1];
            longDate = longDate.split(".")[0];
        }
        return longDate;
    }
    

    function addCallSnapshot(snapshot, house, rDate, houseTitle){
        console.log(house);
        console.log(rDate);
        console.log(houseTitle);
        
        if (!Object.keys(calls).includes(house)) { Object.assign(calls, {[house]:[]}) }

        const callList = calls[house];
        
        snapshot.forEach(snap => {
            const k = snap.key;
            const r = snap.val();
            console.log({k});
            if (r.callType=="Visit") return;
            callList.push(
                {
                    //"CallID": r["aqRef"] ? r["aqRef"] : (r.start + "_" + r.room),
                    "House": houseTitle,
                    "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                    "Zone" : r.zone,
                    "Resident" : "",
                    "Call Point" : r.room,
                    "Call Type" : r.callType,
                    "Start Time" : getTimeFromLongDate((r.startFullDate!==undefined) ? r.startFullDate : r.start),
                    "End Time" : r.end,
                    "Call Duration" : r.duration,
                    "Carer ID" : r.carer,
                    "Care Delivered" : r["Care Delivered"]  
                }
            );
        });
        
        console.log("OBBBBBJKJJECT");
        console.log(calls);
        setCallsUpdated(new Date());



         /*   
        let tcalls = [];//calls;

        snapshot.forEach(snap => {
            const r = snap.val();
            if (r.callType=="Visit") return;
            tcalls.push(
                {
                    //"CallID": r["aqRef"] ? r["aqRef"] : (r.start + "_" + r.room),
                    "House": houseTitle,
                    "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                    "Zone" : r.zone,
                    "Resident" : "",
                    "Call Point" : r.room,
                    "Call Type" : r.callType,
                    "Start Time" : getTimeFromLongDate((r.startFullDate!==undefined) ? r.startFullDate : r.start),
                    //"Start Time" : (r.startFullDate!==undefined) ? r.startFullDate : r.start,
                    "End Time" : r.end,
                    "Call Duration" : r.duration,
                    "Carer ID" : r.carer,
                    "Care Delivered" : r["Care Delivered"]  
                }
            );
        });

        tcalls = tcalls.filter(c=>c["End Time"]!==null);                            //remove null ends
        tcalls = tcalls.filter(c=>c["End Time"]!=="");                              //remove blank ends 
        tcalls = tcalls.filter(c=>c["End Time"]!=="Live");
        tcalls = tcalls.filter(c=>typeof(c["Call Point"])==="string");      //remove if room is undefined
        tcalls = tcalls.map(c =>
            ((c["Carer ID"]) && (c["Carer ID"]!=="") && (c["Carer ID"]!=="Room Unit")) 
            ? { ...c, "Carer ID": mapEmailAddress(c["Carer ID"], userList) }
            : c
        );
        //tcalls = tcalls.reverse();
        tcalls = cleanData(tcalls, siteConfig);
        
        let panelCallsObj = {...panelCalls};

        if (panelCallsObj.hasOwnProperty(house)) {
            panelCallsObj[house] = tcalls;
        }
        else {
            Object.assign(panelCalls, {[house]: tcalls});                
        }
        */
        //setPanelCalls(panelCallsObj);
        //panelCallsToCalls();

    }
    
    
    const getfb = (house, rDate, houseTitle, ref)=> new firebase.database().ref(ref).on("value", snapshot => addCallSnapshot(snapshot, house, rDate, houseTitle));



    useEffect(()=>{
        setUpListeners();
    },[homeSelection]);
    
    return ( 

        <div>
            <div>
                <div>Sumary</div>
                <div>
                {
                Object.keys(calls).map(site=>{
                    return( <div>
                                <div>{site}</div>
                                <div>{calls[site].length}
                                    {/*
                                    calls[site].map(c=>{
                                        return (<div>{
                                                Object.keys(c).map(k=>{
                                                    return(<div><b>{k}: </b>{c[k]}</div>)
                                                })
                                            }</div>);
                                    })
                                */}
                                </div>
                            </div>)
                })
                }

                </div>
            </div>
            
        </div>
    );

};



const CompletedCallssss = (props) => {

    const [callUpdateCount, setCallUpdateCount] = useState(0);
    const [panelCalls, setPanelCalls] = useState({});
    const [calls, setCalls] = useState([]);
    const [callListeners, setCallListeners] = useState([]);
    const [displayTitle, setDisplayTitle] = useState("");
    const [siteConfig, setSiteConfig] = useState([]);
    const EmergencyCalls = fieldFilter(calls, 'Emergency', "Call Type");
    const CallCalls = fieldFilter(calls, 'Call', "Call Type");
    const AccessoryCalls = fieldFilter(calls, 'Accessory', "Call Type");
    const AttendanceCalls = fieldFilter(calls, 'Attendance', "Call Type");
    const CareCalls = fieldFilter(calls, 'CareCall', "Call Type");
    const AssistanceCalls = fieldFilter(calls, 'Assistance', "Call Type");
    const userList = JSON.parse(getLocalSetting("uMails"));


    useEffect(()=>{

        if (calls.length > 0) {
            setCalls([]);
            setPanelCalls({});
        }

        let selectionsList = props.homeSelection;

        selectionsList.map(s=>{
            getCallData(s.value, props.dateSelection, s.label);  
        });
      
    },[props.dateSelection, props.homeSelection]);

    
    useEffect(()=>{
        if (props.printCallBack) props.printCallBack(printObj, excelPrint, printObj);
    }, [calls]);

    const getDateRef = (ref) => {
        let options = getDateSelectionList();
        let n = "";
        options.map((o)=>{
            if (o.label === ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    const mapEmailAddress=(email, userList)=>{
        if (!Array.isArray(userList)) return email;
        userList.map(u=>{
            if (u.email === email) return u.first_name + " " + u.surname;
        });
        return email;
    }


    function getTimeFromLongDate(longDate){
               
        if (longDate===undefined) return "";
        if (longDate.length>10) {
            longDate = longDate.split(" ")[1];
            longDate = longDate.split(".")[0];
        }
        return longDate;
    }
    

    function addCallSnapshot(snapshot, house, rDate, houseTitle){
            
            let tcalls = [];//calls;

            snapshot.forEach(snap => {
                const r = snap.val();
                if (r.callType=="Visit") return;
                tcalls.push(
                    {
                        //"CallID": r["aqRef"] ? r["aqRef"] : (r.start + "_" + r.room),
                        "House": houseTitle,
                        "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                        "Zone" : r.zone,
                        "Resident" : "",
                        "Call Point" : r.room,
                        "Call Type" : r.callType,
                        "Start Time" : getTimeFromLongDate((r.startFullDate!==undefined) ? r.startFullDate : r.start),
                        //"Start Time" : (r.startFullDate!==undefined) ? r.startFullDate : r.start,
                        "End Time" : r.end,
                        "Call Duration" : r.duration,
                        "Carer ID" : r.carer,
                        "Care Delivered" : r["Care Delivered"]  
                    }
                );
            });

            tcalls = tcalls.filter(c=>c["End Time"]!==null);                            //remove null ends
            tcalls = tcalls.filter(c=>c["End Time"]!=="");                              //remove blank ends 
            tcalls = tcalls.filter(c=>c["End Time"]!=="Live");
            tcalls = tcalls.filter(c=>typeof(c["Call Point"])==="string");      //remove if room is undefined
            tcalls = tcalls.map(c =>
                ((c["Carer ID"]) && (c["Carer ID"]!=="") && (c["Carer ID"]!=="Room Unit")) 
                ? { ...c, "Carer ID": mapEmailAddress(c["Carer ID"], userList) }
                : c
            );
            //tcalls = tcalls.reverse();
            tcalls = cleanData(tcalls, siteConfig);
            
            let panelCallsObj = {...panelCalls};

            if (panelCallsObj.hasOwnProperty(house)) {
                panelCallsObj[house] = tcalls;
            }
            else {
                Object.assign(panelCalls, {[house]: tcalls});                
            }
            
            setPanelCalls(panelCallsObj);
            panelCallsToCalls();

    }

    const getfb = (house, rDate, houseTitle, ref)=> new firebase.database().ref(ref).on("value", snapshot => addCallSnapshot(snapshot, house, rDate, houseTitle));

    function getCallData (house, rDate, houseTitle) {
        let ref = house + "/" + getDateRef(rDate);
        callListeners.push(getfb(house, rDate, houseTitle, ref));          
    }

    function panelCallsToCalls(){
        
        let tcalls = [];
        let homeSelection = props.homeSelection ? props.homeSelection : [];
        if (panelCalls==null) return;
        Object.keys(panelCalls).map((k)=>{
            if (homeSelection.filter((h)=>h.value==k).length>0)tcalls.push(...panelCalls[k]);
        });

        tcalls = orderCallsByDateASC(tcalls);
        tcalls = tcalls.reverse();
        setCalls(tcalls);

    }
    
    function orderCallsByDateASC(calls){
        return calls.sort(function(a,b){return (a["Start Time"] > b["Start Time"]) ? 1 : -1});
    }

    const avAllCalls = getAverageTime(calls, "Call Duration");
    const avEmgCalls = getAverageTime(EmergencyCalls, "Call Duration");
    const avAccCalls = getAverageTime(AccessoryCalls, "Call Duration");
    const avAssCalls = getAverageTime(AssistanceCalls, "Call Duration");
    const avCallCalls = getAverageTime(CallCalls, "Call Duration");
    const avAttCalls = getAverageTime(AttendanceCalls, "Call Duration");

    
    const cleanArrayToString=(careArr)=>{
        careArr = careArr.filter(function(item, pos) {
            return careArr.indexOf(item) == pos;
        });
        return careArr.join(",");
    }
    const cleanCareDelivered=(cArr)=>cArr.map(obj =>
        (Array.isArray(obj["Care Delivered"]))
            ? { ...obj, ["Care Delivered"]: cleanArrayToString(obj["Care Delivered"]) } 
            : obj,
    );
 
    const cleanDurationArr=(cArr)=>cArr.map(obj =>
       (obj["Call Duration"]>0)
            ? { ...obj, ["Call Duration"]: Moment("2015-01-01").startOf('day').seconds(obj["Call Duration"]).format('H:mm:ss')} 
            : obj
    );
 

    const printCalls = cleanDurationArr(cleanCareDelivered(calls));


    const printObj = {
        title: displayTitle,
        CallList: printCalls,
        CallSummary:[
            {
                title: "All Calls",
                ["Average Call Length"]: avAllCalls,
                Calls: calls.length
            },
            {
                title: "Emergency",
                ["Average Call Length"]: avEmgCalls,
                Calls: EmergencyCalls.length
            },
            {
                title: "Accessory",
                ["Average Call Length"]: avAccCalls,
                Calls: AccessoryCalls.length
            },
            {
                title: "Assistance",
                ["Average Call Length"]: avAssCalls,
                Calls: AssistanceCalls.length
            },
            {
                title: "Calls",
                ["Average Call Length"]: avCallCalls,
                Calls: CallCalls.length
            },
            {
                title: "Attendance",
                ["Average Call Length"]: avAttCalls,
                Calls: AttendanceCalls.length
            }

        ]
    }
    
    const excelPrint = {
        CallSummary:[
            {
                title: "All Calls",
                ["Average Call Length"]: avAllCalls,
                Calls: calls.length
            },
            {
                title: "Emergency",
                ["Average Call Length"]: avEmgCalls,
                Calls: EmergencyCalls.length
            },
            {
                title: "Accessory",
                ["Average Call Length"]: avAccCalls,
                Calls: AccessoryCalls.length
            },
            {
                title: "Assistance",
                ["Average Call Length"]: avAssCalls,
                Calls: AssistanceCalls.length
            },
            {
                title: "Calls",
                ["Average Call Length"]: avCallCalls,
                Calls: CallCalls.length
            },
            {
                title: "Attendance",
                ["Average Call Length"]: avAttCalls,
                Calls: AttendanceCalls.length
            }

        ],
        CallList: cleanDurationArr(cleanCareDelivered(printCalls)),
        EmergencyCalls: cleanDurationArr(cleanCareDelivered(EmergencyCalls)),
        CallCalls: cleanDurationArr(cleanCareDelivered(CallCalls)),
        AccessoryCalls: cleanDurationArr(cleanCareDelivered(AccessoryCalls)),
        AttendanceCalls: cleanDurationArr(cleanCareDelivered(AttendanceCalls)),
        AssistanceCalls: cleanDurationArr(cleanCareDelivered(AssistanceCalls)),
        CareCalls: cleanDurationArr(cleanCareDelivered(CareCalls)),
    }

    return ( 

            <DashboardSummaryContainer 
                displayTitle={displayTitle}
                All_Calls={calls}
                Emergency={EmergencyCalls}
                Call={CallCalls}
                Accessory={AccessoryCalls}
                Attendance={AttendanceCalls}
                Assistance={AssistanceCalls}
                Care_Call={CareCalls}
            />
   
    );

};



const getDateSelectionList = (props) => {   
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const options = [];
    
    let addDate = new Date();
    let dayBack1 = new Date();
    let dayBack2 = new Date();
    let dayBack3 = new Date();
    
    dayBack1.setDate(dayBack1.getDate() - 1);
    dayBack2.setDate(dayBack2.getDate() - 2);
    dayBack3.setDate(dayBack3.getDate() - 3);
    
    options.push({label:'Today', value: addDate});
    options.push({label:'Yesterday', value: dayBack1});
    options.push({label:days[dayBack2.getDay()], value: dayBack2});
    options.push({label:days[dayBack3.getDay()], value: dayBack3});
    
    return options;
}



function fieldFilter (list, filterValue, filterField) {
    let RTNCALLS = [];
    try {
        RTNCALLS = list.filter(row => row[filterField] ? row[filterField].includes(filterValue) : null).map(filteredName => (filteredName));
    } catch (e) { }

    return RTNCALLS; 
}

function getDateRefDisplay (ref) {
    let n;
    const options = getDateSelectionList();
    
    options.forEach((val) => {
        if (val.label === ref) {
            n = val.value;
        }
    });
    
    return n;
}

export default CompletedCalls;