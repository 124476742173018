import React, {useEffect, useState} from 'react';

const ShowDash = (props) => {

    const selectedSite = props.filterSite;
    const selectedDate = props.filterDate; //setDate

    const dashboardRowContainerStyle = {
        display: "flex",
        flexDirection: "row",
        margin: "2em",
        width: "100%",
        justifyContent: "space-around"
    }
            
            return (
                
                    <div style={dashboardRowContainerStyle} >

                        {props.children}                        

                    </div>
                
            );

  
}

export default ShowDash;


