import React, {useEffect} from 'react';
import Moment from 'moment';
import { useState } from 'react';
import PhoneBatteryLevels from './PhoneBatteryLevels'; 
import CirclePercentageGraph from './CircleGraph';

const ErrorLogs=({selectedHome, selectedDate})=>{
    

    return(
        <div>
            
            <PhoneBatteryLevels />
            <CirclePercentageGraph />
            
        </div>
    );

}

export default ErrorLogs;