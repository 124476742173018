import React, {useContext, useState, lazy, Suspense, Fragment } from 'react';
import '../../css/dashboard.css';
import '../reporting.css';
import './MenuItems.css';
import IMG_LOGO from '../../images/whiteLogo.png';
import {firebaseAuth} from '../../firebase/provider/AuthProvider';
import NavUserMenu from '../NavUserMenu/NavUserMenu';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import DropDownMainMenu from '../DropDown/DropDownMainMenu';
import AssistDropDown from '../DropDown/AssistDropDown';

const navigation = [
]

const navigationss = [
  { name: 'Assist', href: 'assist', current: true },
  { name: 'Vayyar', href: 'assist', current: true },
  { name: 'Care', href: '#', current: false },
  { name: 'Iiwari', href: 'assist', current: true },
  { name: 'Meds', href: '#', current: false },
  { name: 'Team', href: '#', current: false },
  { name: 'Mainenance', href: '#', current: false },
  { name: 'Support', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function NavBar({logo, userOptions, menuOptions}) {
  
  
  return (
    <Disclosure as="nav" className="bg-aqPrimary">
      {({ open }) => (
        <>
          <div className="">
            <div className="topmenubar flex-row-between padding-2">
              <div className="flex-row-between">
                <div className="flex-shrink-0 flex items-center">
                  {logo}
                </div>
                <div className="hidden sm:block sm:ml-6 self-center">
                  <div className="flex space-x-4">
                  
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-aqSecondary text-aqPrimary' : 'text-aqSecondary hover:bg-aqSecondary hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    {menuOptions}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {userOptions}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
  
  
  
  
  return (
    <Disclosure as="nav" className="bg-aqPrimary h-18">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="topmenubar relative flex items-center justify-between h-16">
              {/*
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                }
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                  
              </div>
              */}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  {logo}
                </div>
                <div className="hidden sm:block sm:ml-6 self-center">
                  <div className="flex space-x-4">
                  
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-aqSecondary text-aqPrimary' : 'text-aqSecondary hover:bg-aqSecondary hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    {menuOptions}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
{/*
                <button
                  type="button"
                  className="bg-aqSecondary p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
*/}
                {/* Profile dropdown */}
                {userOptions}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


const MainNavBar = (props) => {
  
  return <NavBar
  logo={<a href="/">
    <img
      //className="hidden lg:block h-8 w-auto"  
      alt="Arquella Logo" 
      className="header_logo" 
      src={IMG_LOGO} /></a>}
  menuOptions={<>
    <AssistDropDown />
  </>}
  userOptions={<UserOptions />}

/>;

}


function Dashboard(){
  return(
    <div class="relative inline-block text-left z-20">
        <button type="button" class="inline-flex justify-center w-full rounded-md px-4 py-2 bg-aqPrimary text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
          Dashboard
        </button>
    </div>
  );
}


function MenuOptionsDropdown(){ return <DropDownMainMenu />;  }



function UserOptions() {
  const {handleSignout,} = useContext(firebaseAuth);
  return <NavUserMenu USER={{user:{name:"", email:"andrewchapman@arquella.co.uk"}}} signOut={handleSignout} />;
}


export default MainNavBar;

