 import firebase from 'firebase'

 /*
 const firebaseConfig = {
    apiKey: "AIzaSyDmO7UG0-RgjJNn0gLgrXiLxSknREYPYfw",
    authDomain: "arquella-nursecall.firebaseapp.com",
    databaseURL: "https://arquella-nursecall.firebaseio.com",
    projectId: "arquella-nursecall",
    storageBucket: "arquella-nursecall.appspot.com",
    messagingSenderId: "262436294742",
    appId: "1:262436294742:web:8bf3d84f4b253f1d992bf7",
    measurementId: "G-19XVGEYY54"
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e.firebaseio.com",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();  