import React from 'react';
import '../../css/dashboard.css';
import '../reporting.css';
import IMG_ASSIST from '../../images/icons/aida_assist.png';
import IMG_CARE from '../../images/icons/aida_care.png';
import IMG_TEAM from '../../images/icons/aida_team.png';
import IMG_MAINTENANCE from '../../images/icons/aida_settings.png';
import IMG_SUPPORT from '../../images/icons/aida_support.png';
import { Link } from "react-router-dom";
import './MenuItems.css';
import MenuItem from './MenuItem';


const MainMenuItems = ({user, site, version}) => {
  return (
    <div className='content_spacing'>
      <div>
        <b>Site Name:</b> {site[0].name}
      </div>
      <div>
        <b>User:</b> {user[0]["First Name"]} {user[0]["Surname"]}
      </div>
      <MainMenuItemss></MainMenuItemss>
      <div className='font-faded'>Arquella AIDA: {version}</div>
    </div>
  ); 
}

const MenuOption=({menuname, imgPath, link}) => {

  return(
    <Link to={link}><MenuItem menuname={menuname} imgPath={imgPath} /></Link>
  );
};


const MainMenuItemss = () => {
  
    let care = localStorage.getItem("care");

    return (
      <div>
          
        <div className="main_menu_cards">
          <MenuOption menuname="Assist" imgPath={IMG_ASSIST} link="/assist" />
          <MenuOption menuname="Care" imgPath={IMG_CARE} link="/care" />  
        </div>
        
       
        <div className="main_menu_cards">
          <MenuOption menuname="Maintenance" imgPath={IMG_MAINTENANCE} link="/maintenance" />
          <MenuOption menuname="Support" imgPath={IMG_SUPPORT} link="/about" />  
        </div>
      </div>
    ); 
  }
  
export default MainMenuItems;