import React from "react";
import './dashboard.css';
import IMG_LOGO from '../../images/logo.jpg';
import { SideNav } from "../../components/Navigation";
import CallsPerDay from './Widgets/CallsPerDay';
import C1 from './Widgets/C1';

const NDashboard=()=>{

    return(
        <>
            <header className="flex-row-between" >
                <img
                    alt="Arquella Logo" 
                    className="header_logo" 
                    src={IMG_LOGO} />

                <div className="flex-row-between">
                    <div>Dashboard</div>
                    <div>Maintenance</div>
                    <div>Reports</div>    
                </div> 
            </header>
            
            <main className="ndash">

                <div className="ndash_side">
                    <div>Custom</div>
                    <div>Assist</div>
                    <div>Move</div>
                    <div>Monitor</div>
                </div>
                <div className="ndash_content">
                    <div className="promoBar">Promo bar</div>
                    <div>27 Jul 2022 - 2 Aug 2022</div>
                    <div className="ndash_layout">
                        <CallsPerDay />
                        <C1 />
                    </div>

                </div>
            </main>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default NDashboard;