import React from 'react';
import './DataTable.css';


const ColumnFilter = ({column, data}) => {
    
    const {filterValue, setFilter} = column;  

    return <input className="DataColumnFilter" value={filterValue ||''} onChange={(e) => setFilter(e.target.value)} />
      
}


export default ColumnFilter;