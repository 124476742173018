import React, {useState, useContext} from "react";

const AuthContext = React.createContext({
    
    isLoggedIn: false,
    user: {},
    sites: [],
    permissions: [],
    sitePanels: [],
    
    onLogout: ()=>{}, 
    onLogin: (email, password)=>{},

});


export default AuthContext;