import React, {useState, useRef, useEffect} from 'react';

const AssistDropDown=()=>{

  const menuItems = [
    {
      title: "Home",
      url: "",
      submenu: [
        {
          title: "Assist",
          submenu: [
            {
              title: "Dashboard",
              url: "/assist"
            },
            {
              title: "Reports",
              submenu: [
                {
                  title: "Performance Report",
                  url: "/repgraphs"
                },                
                {
                  title: "All Call Data",
                  url: "/call_data"
                },
                /*{
                  title: "Interactive Dashboard",
                  url: "/idashboard"
                },*/
              ],
            },
          ],
        },
        /*
        {
          title: "Care",
          url: "/care"
        },
        */
        /*
        {
          title: "Team",
          url: "/teams"
        },
        */
        {
          title: "Maintenance",
          url: "/maintenance"
        },
        {
          title: "Support",
          url: "/support"
        }        
      ],
    },
  ];
   
   const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenuaq" : "";
    return (
      <ul className={`dropdownaq ${dropdownClass} ${dropdown ? "show" : ""}`}>
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
        ))}
      </ul>
    );
  };
   

  const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
  
    let ref = useRef();
  
    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);
  
    const onMouseEnter = () => {
      window.innerWidth > 960 && setDropdown(true);
    };
  
    const onMouseLeave = () => {
      window.innerWidth > 960 && setDropdown(false);
    };
  
    return (
      <li
        className="menu-itemsaq"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items.submenu ? (
          <>
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
              className={(items.title=="Home") ? "text-white" : ""}
            >
              {items.title}{" "}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrowaq" />}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </>
        ) : (
          <a href={items.url}>{items.title}</a>
        )}
      </li>
    );
  };

  const Navbar = () => {
    return (
      <nav>
        <ul className="menusaq">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
          })}
        </ul>
      </nav>

    );
  };

    return(
    
      <Navbar />
    
    );
}

export default AssistDropDown;