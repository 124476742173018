import React, {useState, useEffect, useRef, useContext} from 'react';
import DropSelect from '../../components/common/DropSelect/DropSelect';
import DropDownMultiSelect from '../../components/DropDown_MultiSelect';
import './Dashboard.css';
import LiveCallController from './LiveCall/LiveCallController';
import NewLiveCallController from './LiveCall/NewLiveCallController';
import 'bootstrap/dist/css/bootstrap.min.css'
import PrintOptions from '../../components/PrintOptions';
import CompletedCalls from './CompletedCalls';
import getLocalSetting from '../../components/get_local_setting';   
import AuthContext from '../../AuthContextProvider';
import MaintenanceDashboard from './Maintenance';


const ArqDashboard = ({USER, permissions}) => {

    console.log({USER});

    const [homeOptions, setHomeOptions] = useState([]);
    const [homeSelections, setHomeSelections] = useState([]);
    const [dateSelection, setDateSelection] = useState("Today");

    function getPanelOptions() {
        console.log("Get Panel Options");
        const tmpVals = [];
        USER.site.map(s=>{
            tmpVals.push({"label": s.name, "value": s.panel});
        });
        setHomeOptions(tmpVals);
        setHomeSelections(tmpVals);
    }

    
    function printCallBack(print, excel, pdf){
    /*    
        if (
            (printValues.print !== print) ||
            (printValues.excel !== excel) ||
            (printValues.pdf !== pdf)
        ) 
        {
            setPrintValues({
                print: print,
                excel: excel,
                pdf: pdf
            });
        }
      */  
    }

    const DateSelection = (props) => {   

        const options = getDateSelectionList();
        if ((dateSelection === "") && (options.length>0)) {dateChanged(options[0].label)}
        
        return <DropSelect options={options} selectedVal={dateSelection} changeHandler={dateChanged} />
    }

    function dateChanged(dateVal) { setDateSelection(dateVal); }

    useEffect(()=>{ getPanelOptions(); },[]);

    return (

        <div id="AIDA_NAV_MAIN">

        <NavPane>

            <div>
                
                <div>
                    <div><p>Home</p></div>
                    <div>
                        {
                        (homeOptions.length>0)
                        ?
                            <DropDownMultiSelect 
                            options={homeOptions} 
                            selections={homeSelections} 
                            filterCall={setHomeSelections} 
                            />
                        :   <></>
                        }
                        
                    </div>
                </div>
                <p>&nbsp;</p>
                <div>
                    <div><p>Set Time Period</p></div>
                    <div><DateSelection /></div>
                </div>    
            </div>

        </NavPane>


        <div className='dashboard_grid'>
            {
            homeSelections.map(h=>{
                return(<div>{h.value}</div>)
            })
            }

            <CompletedCalls
                USER={USER}
                homeSelection={homeSelections}
                dateSelection={dateSelection}
                printCallBack={printCallBack}
            />
        </div>
    
    </div>

    );

}

    

const NavPane = (props) => {
    //AIDA_NAV
    const [show, setShow]=useState(true);
    const className = ((show) ? "AIDA_NAV" : "nav-collapse");
    


    return (
        <div 
            id="AIDA_NAV" 
            className={className}
        >
            {/*
            <div onClick={()=>{setShow(!show)}}>
                {(show) ? "Hide" : "Show"}
            </div>
            */}

            {(show===true) && props.children}
        </div>
    );
}


const getDateSelectionList = (props) => {   
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const options = [];
    
    let addDate = new Date();
    let dayBack1 = new Date();
    let dayBack2 = new Date();
    let dayBack3 = new Date();
    
    dayBack1.setDate(dayBack1.getDate() - 1);
    dayBack2.setDate(dayBack2.getDate() - 2);
    dayBack3.setDate(dayBack3.getDate() - 3);
    
    options.push({label:'Today', value: addDate});
    options.push({label:'Yesterday', value: dayBack1});
    options.push({label:days[dayBack2.getDay()], value: dayBack2});
    options.push({label:days[dayBack3.getDay()], value: dayBack3});
    
    return options;
}



/*
const ArqDashboard2 = ({USER, permissions}) => {

    console.log({USER});
    const ctx = useContext(AuthContext);


    function siteCodeFromPanelRef(ref){
        const pos = ref.indexOf("CallRecords");
        if (pos > -1) ref = ref.substring(0, pos);
        console.log("return ref::" + ref);
        return ref;
    }

    const [homeSelections, setHomeSelections] = useState(getPanelOptions());
    const [homeSelection, setHomeSelection] = useState((getLocalSetting('sites')!=null) ? JSON.parse(getLocalSetting('sites'))[0].label : "");
    
    const [homeOptions, setHomeOptions] = useState(getPanelOptions());
    const [CompleteCallsPage, setCompleteCallsPage] = useState(<div></div>);

    const [printValues, setPrintValues] = useState({
        print: {},
        excel: {},
        pdf: {}
    });




//    const HomeSelection = (props) => { return <DropSelect options={homeOptions} selectedVal={homeSelection} changeHandler={siteChanged} /> }



    


    const updateCompletedCalls=()=>{
        setCompleteCallsPage(
            
        );
    }

    useEffect(()=>{ updateCompletedCalls(); },[homeSelections, dateSelection]);
    
    function hasPermissionGranted(permissions, permissionToHave){
        if (permissions==null) return false;
        if (typeof(permissions)!=="object") return false;
        let rtnVal = false;

        Object.keys(permissions).map(p=>{
            if ((permissions[p].permission===permissionToHave)&&(permissions[p].granted===1)) rtnVal = true;
        });
        return rtnVal;
    }


    const showMaintenance = hasPermissionGranted(permissions, "maintenance");

    let liveLocation = "";
    const tmpHome = JSON.parse(getLocalSetting('sites'))[0].value
    
    if (tmpHome.indexOf('_site/calls') > -1) {
        liveLocation = tmpHome.replace('_site/calls', 'PhoneLiveCalls');
    }

    

    return (

        <div id="AIDA_NAV_MAIN">

        <NavPane>

        <div>



        </div>

        <p>&nbsp;</p>        

        <PrintOptions
            wordOBJ={printValues.print}
            excelOBJ={printValues.excel}
            pdfOBJ={printValues.pdf}
        />

        </NavPane>


    <div className='dashboard_grid'>

        {CompleteCallsPage}

        {
        (liveLocation==="")
        ?   <LiveCallController homeSelection={homeSelection} dateSelection={dateSelection} sitePanels={(ctx.sitePanels ? ctx.sitePanels : [])} />
        :   <NewLiveCallController homeSelection={liveLocation} dateSelection={dateSelection} sitePanels={(ctx.sitePanels ? ctx.sitePanels : [])} />
        }    
            
    </div>
    
    </div>

    );

}
*/



export default ArqDashboard;