import React, {Component} from "react";
import IMG_SUMMARY_CALL from '../../images/resident_summary/call.png';
import IMG_SUMMARY_CLOCK from '../../images/resident_summary/clock.png';
import IMG_SUMMARY_USERPLUS from '../../images/resident_summary/userplus.png';
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/light.css';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import moment from 'moment';
import RawDataTable from '../../components/common/raw_data_table/rawdatatable';
import { Modal } from 'react-bootstrap';
import CallReportContainer from '../../components/CallReportContainer/CallReportContainer';
import Journey from '../../components/graphs/journeygraph/journey';


const ref = React.createRef();

export default class ResidentReportContent extends React.Component {
  
  state = {
    data: [],
    resDetails: this.props.resDetails,
    showData: false,
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resDetails !== this.props.resDetails) {
      let userName = this.props.resDetails.first_name + " " + this.props.resDetails.surname;
      let tmpCalls = demoCalls[userName];
      this.setState({
        resDetails: this.props.resDetails,
        data: tmpCalls
      });
    }
  }

  PopupClick() {
    console.log("POPOPUPCLICK");
  }


  render() {
    
    let calls = this.state.data;
    const totalCalls = 10; //calls.length;
    let longestCall = "00:20:00";//0; //seconds
    let timeBetween = 0; //seconds
    let timeBetweenAvr = 0;
    let lastCallEnd = null;

    const emgCalls = [];
    let callCalls = [];
    const attendanceCalls = [];

    calls.map(c=>{
        
        if (Number.isInteger(c.duration)) {     //check if new longest call duration
            if (parseInt(c.duration) > longestCall){longestCall = c.duration}
        }

        if (lastCallEnd == null) {lastCallEnd = moment(c.end_date)}

        var duration = moment.duration(moment(c.end_date).diff(lastCallEnd));
        timeBetween = timeBetween + duration.asSeconds();
        //setup the subArrays
        if (c.call_type=="Emergency") {emgCalls.push(c)};
        if (c.call_type=="Call") {callCalls.push(c)};
        if (c.call_type=="Attendance") {attendanceCalls.push(c)};
        
 
  });

    timeBetweenAvr = timeBetween / totalCalls;
    
    let topCallCount = 0;
    let topCalls = "Call";
    
    if (emgCalls.length > topCallCount) {
        topCallCount = emgCalls.length;
        topCalls = "Emergency";
    }
    
    if (attendanceCalls.length > topCallCount) {
        topCallCount = attendanceCalls.length;
        topCalls = "Attendance";
    }
    
    if (callCalls.length > topCallCount) {
        topCallCount = callCalls.length;
        topCalls = "Calls";
    }
    
    callCalls = translateArray(callCalls);

    return (
    
        <div ref={ref} className="resident_report_main">  
            
          <div>
              <Modal show={this.state.showData}>
                  <RawDataTable />
              </Modal>
          </div>

          <ResidentContainer resDetails={this.state.resDetails}>
          
          <br />


            <SummaryData 
              total={totalCalls}
              longest={longestCall}
              between={timeBetweenAvr}
              popular={topCalls} 
              />
          
            <br />
            <div className="reports_resident_charts_container">
            
              <CallReportContainer fullWidth={true} callType="Emergency">
                  <Journey data={emgdemodata} type="Emergency"/>
              </CallReportContainer>
              <p>&nbsp;</p>
              <CallReportContainer fullWidth={true} callType="Call">
                <Journey data={calldemodata} type="Call"/>
              </CallReportContainer>
              <p>&nbsp;</p>
              <CallReportContainer fullWidth={true} callType="Attendance">
                <Journey data={attdemodata} type="Attendance" />
              </CallReportContainer>
            
            </div>
          
          </ResidentContainer>
        <p>&nbsp;</p>
        </div>

    );
  }

}



function translateArray(arr) {
    let rtn = [];
    let groupedCall = [];

    let hasEmg = false;
    let hasCall = false;
    let hasAttend = false;
    let hasAssist = false;
    let addToMain = false;

    arr.map(c=>{
      
      if ((c.call_type=="Emergency")&&(hasEmg)) {addToMain = true;}
      if ((c.call_type=="Call")&&(hasCall)) {addToMain = true;}
      if ((c.call_type=="Attendance")&&(hasAttend)) {addToMain = true;}
      if ((c.call_type=="Assistance")&&(hasAssist)) {addToMain = true;}

      if (addToMain) {
        rtn.push(groupedCall);
        groupedCall = [];
        groupedCall.push(c);
      }
      else{
        groupedCall.push(c);
      }
      
    });
    
    return rtn; 
}


const SummaryData = (props) => (
    <div className="report_resident_summary_container">
      
      <div className="report_resident_summary_header">Summary</div>
      
      <div className="report_resident_summary_main">
      <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_CALL} />
          <div className="summary_widget_title">Total Calls</div>
          <div className="summary_widget_value">{props.total}</div>
        </div>
        <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_CLOCK} />
          <div className="summary_widget_title">Longest Call</div>
          <div className="summary_widget_value">{props.longest}</div>
        </div>
        <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_USERPLUS} />
          <div className="summary_widget_title">Most Popular <br />Initial Call Type</div>
          <div className="summary_widget_value">{props.popular}</div>
        </div>
      </div>
    </div>
  );


const ResidentContainer = (props) => (

<div className="reports_resident_container">

      <div className="reports_resident_container_header">
        <div className="res_header_details">
          <div><GetAvatar firstname="B" surname="" /></div>
          <div className="res_header_detail_right">
            <div className="res_header_details">
              <div className="res_details">{props.resDetails.first_name} {props.resDetails.surname}</div>
            </div>
            <div className="res_header_details">
              <div className="res_details"><b>UIN:</b> {props.resDetails.uin}</div>
            </div>
            <div className="res_header_details">
            <div className="res_details"><b>Room:</b> {props.resDetails.room}</div>
            </div>
          </div>
        </div>   
      </div>
      <div className="reports_resident_container_main">
        {props.children}
      </div>
    </div>
);




class PickDate extends Component {
  state = {
    v: '2016-01-01 01:01',
    onChange: (_, str) => {
      console.info(str)
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState(state => ({
        v: state.v.replace('2016', '2017'),
        onChange: (_, str) => {
          console.info('New change handler: ', str)
        }
      }))
    }, 2000)
  }

  render() {
    const { v } = this.state

    return (
      <main>
        <Flatpickr data-enable-time className='test'
          onChange={[
            (_, str) => console.info(str),
            () => {} // test hookPropType
          ]} />
        <Flatpickr data-enable-time defaultValue='2016-11-11 11:11'
          onChange={(_, str) => console.info(str)} />
        <Flatpickr data-enable-time value={v}
          onChange={(_, str) => console.info(str)} />
        <Flatpickr value={v} options={{minDate: '2016-11-01'}}
          onChange={(_, str) => console.info(str)} />
        <Flatpickr value={[v, '2016-01-10']} options={{mode: 'range'}}
          onChange={(_, str) => console.info(str)} />
        <Flatpickr onChange={this.state.onChange}
          onOpen={() => { console.info('opened (by prop)') }}
          options={{
            onClose: () => {
              console.info('closed (by option)')
            },
            maxDate: new Date()
          }} />
        <Flatpickr value={new Date()}
          onChange={(_, str) => console.info(str)} />
        <Flatpickr value={v} options={{wrap: true}}
          onChange={(_, str) => console.info(str)}
        >
          <input type='text' data-input />
          <button type='button' data-toggle>Toggle</button>
          <button type='button' data-clear>Clear</button>
        </Flatpickr>
        <Flatpickr
          defaultValue='2019-05-05'
          onCreate={(flatpickr) => { this.calendar = flatpickr }}
          onDestroy={() => { delete this.calendar }}
          render={({ defaultValue }, ref)=>{
            return (
              <div>
                <input defaultValue={ defaultValue } ref={ref} />
                <button onClick={() => this.calendar.setDate(new Date())}>Today</button>
              </div>
            )
          }} />
      </main>
    )
  }
}

const GetAvatar = (props) => {
  let init1 = "";
  let init2 = "";
  
  try {
    init1 = props.firstname.substring(0, 1);
  } catch(err){console.log("Error getting values1: " + err);}
  try {
    init2 = props.surname.substring(0, 1);
  } catch(err){console.log("Error getting values2: " + err);}

  let init = init1 + init2;

  return (
    <div className="avatar">
      <label className="avatar">{init}</label>
    </div>
  );
}



function HomeSelection(props) {   
  
  console.log("HomeSelection");
  console.log(props);

  const options = [{label:'Arquella Demo', value:'Arquella Demo'}];
  return <ItemRenderer options={options} title="Arquella Demo" selectedVal="Arquella Demo" changeHandler={changeSite} option="home" />

}


function ZoneSelection(props) {   
    console.log(props);

  const options = [{label:'Zone1', value:'Zone1'},{label:'Zone2', value:'Zone2'},{label:'Zone3', value:'Zone3'}];
  return <ItemRenderer options={options} title="Arquella Demo" selectedVal="Arquella Demo" changeHandler={changeZone} />

}

function ResidentSelection(props) {   
  console.log(props);

const options = [{label:'Joe Bloggs', value:'Zone1'}];
return <ItemRenderer options={options} title="Joe Bloggs" selectedVal="Joe Bloggs" changeHandler={changeZone} />

}

function CarerSelection(props) {   
console.log(props);

const options = [{label:'Zone1', value:'Zone1'}];
return <ItemRenderer options={options} title="Arquella Demo" selectedVal="Arquella Demo" changeHandler={changeZone} />

}

const ItemRenderer = ({ options, title, selectedVal, changeHandler }) => (

<React.Fragment>

<Select
  multi="true"
  options={options}
  values={[]}
  itemRenderer={({ item, methods }) => (
    
    <StyledItem>
                  {item.disabled ? (
        <div aria-disabled>{item.label}</div>
      ) : (
        <div onClick={() => changeHandler(item.label)}>
          <input onChange={() => methods.addItem(item)} type="checkbox" checked={(item.label == selectedVal)} /> {item.label}
        </div>
      )}
    </StyledItem>
  )}
  onChange={(value) => {
    //(`%c > onChange ${title} `, 'background: #555; color: tomato', value);

  }
}
/>
</React.Fragment>
);

ItemRenderer.propTypes = {};

const StyledItem = styled.div`
padding: 10px;
color: #555;
border-radius: 3px;
margin: 3px;
cursor: pointer;
> div {
display: flex;
align-items: center;
}
input {
margin-right: 10px;
}
:hover {
background: #f2f2f2;
}
`;

const changeSite = (site) => {
 //updatestate
}

const changeZone = (zone) => {
  //updatestate
  
 }

 const changeCarer = (carer) => {
  //updatestate
 }

 const changeCallType = (callType) => {
  //updatestate
 }


 const demoCalls = {
  "David Chapman": [
        {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
        {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
        {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 5, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
        {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
      ],
      "Thomas Hand": [
        {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
        {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
        {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 5, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
        {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
      ]
,  "Constance Calvert": [
  {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
  {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
  {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 5, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
  {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
]
,  "Beatrice Senior": [
  {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
  {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
  {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 5, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
  {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
]
,  "Peggy Sentance": [
  {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
  {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16  , "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
  {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 43, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
  {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
]
,  "Cephas Raymond": [
  {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
  {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
  {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 1, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
  {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
]



 }

 let emgdemodata = [
  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Emergency",
        start: "12:54",
        end: "12:56",
        duration: 2,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Assistance",
        start: "12:56",
        end: "12:59",
        duration: 3
      },
      {
        caption: "",
        type: "Attendance",
        start: "12:59",
        end: "13:08",
        duration: 9
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },
  
  {
    title: '10:50',
    date: "29/06/2021",
    start_time: "10:50",
    end_time: "10:59",
    location: "Bedroom 28",
    duration: "00:09:00",
    calls:[
      {
        caption: "",
        type: "Emergency",
        start: "10:50",
        end: "10:51",
        duration: 2,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Attendance",
        start: "10:51",
        end: "10:59",
        duration: 12
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Emergency",
        start: "12:54",
        end: "12:56",
        duration: 5,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Assistance",
        start: "12:56",
        end: "12:59",
        duration: 8
      },
      {
        caption: "",
        type: "Attendance",
        start: "12:59",
        end: "13:08",
        duration: 1
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

];

let calldemodata = [
  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Call",
        start: "12:54",
        end: "12:56",
        duration: 14,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },
  
  {
    title: '10:50',
    date: "29/06/2021",
    start_time: "10:50",
    end_time: "10:59",
    location: "Bedroom 28",
    duration: "00:09:00",
    calls:[
      {
        caption: "",
        type: "Call",
        start: "10:50",
        end: "10:51",
        duration: 2,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Attendance",
        start: "10:51",
        end: "10:59",
        duration: 12
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },
  {
    title: '10:50',
    date: "29/06/2021",
    start_time: "10:50",
    end_time: "10:59",
    location: "Bedroom 28",
    duration: "00:09:00",
    calls:[
      {
        caption: "",
        type: "Call",
        start: "10:50",
        end: "10:51",
        duration: 2,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Attendance",
        start: "10:51",
        end: "10:59",
        duration: 12
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Call",
        start: "12:54",
        end: "12:56",
        duration: 7,
        carer: {
          name: "Jayne Rowbotham",
          cid: "1234"
        }
      },
      {
        caption: "",
        type: "Assistance",
        start: "12:56",
        end: "12:59",
        duration: 3
      },
      {
        caption: "",
        type: "Attendance",
        start: "12:59",
        end: "13:08",
        duration: 2
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

];

let attdemodata = [
  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Attendance",
        start: "12:59",
        end: "13:08",
        duration: 9
      },
      {
        caption: "",
        type: "Assistance",
        start: "12:56",
        end: "12:59",
        duration: 3
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },
  
  {
    title: '10:50',
    date: "29/06/2021",
    start_time: "10:50",
    end_time: "10:59",
    location: "Bedroom 28",
    duration: "00:09:00",
    calls:[
      {
        caption: "",
        type: "Attendance",
        start: "10:51",
        end: "10:59",
        duration: 12
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

  {
    title: '12:54',
    date: "12/03/2021",
    start_time: "12:54",
    end_time: "13:14",
    location: "Bedroom 28",
    duration: "00:20:00",
    calls:[
      {
        caption: "",
        type: "Attendance",
        start: "12:59",
        end: "13:08",
        duration: 12
      },
      {
        caption: "end",
        type: "",
        start: "",
        end: "",
        duration: 2
      }
    ]
  },

];
