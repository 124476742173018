import React, {lazy, Suspense, useContext} from 'react';
import '../../css/dashboard.css';
import '../reporting.css';
import './MenuItems.css';
import ResidentReport from '../../pages/ResidentReport/Resident_Report';
import { BrowserRouter, Route} from "react-router-dom";
import ArqDashboard from '../../pages/Assist/Dashboard';
import NodeTest from '../../nodetesting/nodetest';
import ChartSuite from '../../ChartSuite/ChartSuite';
import MainMenuItems from './MainMenuItems';
import MainNavBar from './MainNavBar';
import { MainPageLayout, MainFrame, ContentFrame } from '../Navigation';
import { decrypt } from '../../encrypt/encryptionHandler';
import AuthContext from '../../AuthContextProvider';
import MultiPanelDashboardPage from '../MultiPanelDashboard/MultiPanelDashboardPage';
import CallCalendarPage from '../CallCalendar/CallCalendarPage';
import UserAdmin from '../../pages/UserAdmin/UserAdmin';
import LocationPage from '../../pages/Location/Location';
import NDashboard from '../../pages/NDashboard/NDashboard';
import ErrorLogs from '../../pages/ErrorLogs/ErrorLogs';

{/** Lazy loading routes */}
const Care = lazy(() => import('../care/Care'));
const SupportScreen = lazy(() => import('../support/support'));
const AIDATeam = lazy(() => import('../../teams/TeamMembers'));
const CallDataFilter = lazy(() => import('../../components/CallDataFilter/CallDataFilter'));
const CallDataFilterOrig = lazy(() => import('../../components/CallDataFilterOrig/CallDataFilter'));
const CallDataFilterNew = lazy(() => import('../../components/CallDataFilterNew/CallDataFilter'));
const ReportsCharts = lazy(() => import('../../components/CallDataFilter/ReportsCharts'));
const ReportsChartsP = lazy(() => import('../CallDataFilterP/ReportsCharts'));
const Dashboard = lazy(() => import('../../pages/NewDashboard/Dashboard'));
const GetPCSCareMainMenu = lazy(() => import('../../pages/PCSCare/PSCCare'));
const Maintenance = lazy(() => import('../../pages/Maintenance/Maintenance'));
const Onboarding = lazy(() => import('../../pages/Onboarding/Onboarding'));
const UserSiteManagement = lazy(()=>import ('../../pages/Admin/UserSiteManagement'));
const PanelTesting = lazy(()=>import ('../PanelTesting/PanelTesting'));
const Trends = lazy(()=>import ('../../components/Trends/Trends'));

function MainMenu({USER}) {

  const ctx = useContext(AuthContext);
  
  if (USER===undefined) {
    const tmp = localStorage.getItem("user_object");
    USER = JSON.parse(JSON.parse(decrypt(JSON.parse(tmp))));
  }
  
  return(
    <>

      <MainPageLayout>        
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
          <Route exact path="/ndash" component={NDashboard} />
          
            <Route path="/"><MainNavBar /></Route>

          <MainFrame>
            <Route exact path="/">
              <ContentFrame>
                <MainMenuItems user={ctx.user} site={ctx.sites} version={"1.4.3.7"} />
              </ContentFrame>
            </Route> 
            <Route exact path="/repgraphs">  
              <ReportsChartsP 
                perspective="Call"
                chartsList={[{ name: 'Call Type Breakdown' },{ name: 'Average Response Times' }]} 
                filterViewSelections={['Homes', 'Zones', 'Call Types', 'Call Points', 'Carers']}  
              />
            </Route>
            {/*<Route exact path="/otsukare" component={Trends} />*/}
            <Route exact path="/Trends" component={Trends} />
            <Route exact path="/zzzpaneltesting" component={PanelTesting} />
            <Route exact path="/onboarding" component={Onboarding } />
            <Route exact path={"/errorlogs"} component={ErrorLogs} />
            <Route exact path={"/idashboard"} component={Dashboard} />
            <Route exact path={"/report"} component={CallDataFilter} />
            <Route exact path={"/call_data"} component={CallDataFilterOrig} />
            <Route exact path={"/call_datan"} component={CallDataFilter} />
            <Route exact path="/rep_res_call_data" component={ResidentReport} />
            <Route exact path="/chartsuite" component={ChartSuite} />
            <Route exact path="/UserSiteManagement" component={UserSiteManagement} />
            <Route exact path={["/about", "/support"]} component={SupportScreen} />
            <Route exact path={["/teams","/team"]}><AIDATeam USER={USER} /></Route>
            <Route exact path={["/assist","/dashboard"]}><ArqDashboard permissions={ctx.permissions} USER={USER} /></Route>
            <Route exact path="/node" component={NodeTest} />
            <Route exact path="/maintenance"><Maintenance siteValue={USER.site[0].code} siteDisplayName={USER.site[0].name} /></Route>
            <Route exact path="/chartsuite" component={ChartSuite} />
            <Route exact path={["/multidash"]}><MultiPanelDashboardPage USER={USER} /></Route>
            <Route exact path={["/callcalendar"]}><CallCalendarPage USER={USER} /></Route>
            <Route exact path={"/useradmin"} component={UserAdmin} />
            <Route exact path={"/location"} component={LocationPage} />
            <Route exact path={"/componenttest"} component={LocationPage} />
            {/* Care routes */}
            <Route exact path="/care" component={Care} />
            <Route path="/care/pcs" component={GetPCSCareMainMenu} />
            <Route exact path={["/care/dashboard"]} component={ArqDashboard} />
            <Route exact path="/care/reports">
              <ReportsCharts  
                perspective="Care" 
                chartsList={[{name:'Care Report', value:''}]} 
                filterViewSelections={['Homes']}
              />
            </Route>
            
            </MainFrame>

          </Suspense>
        </BrowserRouter>
    
      </MainPageLayout>
    </>
  );

}

export default MainMenu;

