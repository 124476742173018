import React, {useState, useRef, useEffect, componentRef, useContext} from 'react';
import CompletedDashboard from './Dashboard';
import EventsList from './EventsList';
import AuthContext from '../../AuthContextProvider';
import firebase from 'firebase';

const MultiPanelDashboardPage = (props) => {
    
    const ctx = useContext(AuthContext);
    
    return (
        <div id="AIDA_NAV_MAIN">
            <div className='flex flex-column'>
                <MonitorLivePhoneDB siteName="coxbench" />
                <MonitorLivePhoneDB siteName="cottinghammanor" />
                <MonitorLivePhoneDB siteName="dowerhouse" />
                <MonitorLivePhoneDB siteName="tranby" />
                <MonitorLivePhoneDB siteName="abbeycourt" />
                <MonitorLivePhoneDB siteName="AgnesArthur" />
                <MonitorLivePhoneDB siteName="arquellademostandone" />
                <MonitorLivePhoneDB siteName="arquellahometwo" />
                <MonitorLivePhoneDB siteName="beverlyparklands" />
                <MonitorLivePhoneDB siteName="coneygarlodge" />   
                <MonitorLivePhoneDB siteName="countrycourt" />
                <MonitorLivePhoneDB siteName="davytest" />
                <MonitorLivePhoneDB siteName="erskine" />
                <MonitorLivePhoneDB siteName="greatglens" />
                <MonitorLivePhoneDB siteName="haxby" />
                <MonitorLivePhoneDB siteName="haddonhouse" />          
                <MonitorLivePhoneDB siteName="inchinnan" />
                <MonitorLivePhoneDB siteName="monchoisy" />
                <MonitorLivePhoneDB siteName="larkswood" />
                <MonitorLivePhoneDB siteName="office" />
                <MonitorLivePhoneDB siteName="summerhayes" />
                <MonitorLivePhoneDB siteName="nyton2" />
                <MonitorLivePhoneDB siteName="lindseyhall" />
                <MonitorLivePhoneDB siteName="louthmanor" />
                <MonitorLivePhoneDB siteName="magdalen" />
                <MonitorLivePhoneDB siteName="sowerbyhouse" />
                <MonitorLivePhoneDB siteName="millbrookmanor" />
                <MonitorLivePhoneDB siteName="mere2" />
                <MonitorLivePhoneDB siteName="updatetest" />
                <MonitorLivePhoneDB siteName="vayyardemo" />
                <MonitorLivePhoneDB siteName="westmelton" />
                <MonitorLivePhoneDB siteName="woodlands" />

            </div>
        </div>
    );
}



const MonitorLivePhoneDB=({siteName})=>{

    const [data, setData] = useState([]);

    function getConfig(){
        firebase.database().ref(siteName + "PhoneLiveCalls").on("value", snapshot => {            
            let eData = [];
            snapshot.forEach(snap => {                
                eData.push({"Name":[snap.key], "Value":snap.val()});
            });
            setData(eData);
        });
    }

    useEffect(()=>{ getConfig(); }, []);

    function subtractHours(date, hours) {
        //date.setHours(date.getHours() - hours);
      
        return date;
    }

    function toDateFormat(strDate){
        if (strDate==undefined) return "";
        //strDate = strDate.replace(" ", "T");
        let d = new Date().setTime(Date.parse(strDate));
        
        return d;
    }

    function removeRecord(recordpath){
        recordpath = siteName + "PhoneLiveCalls/" + recordpath;
        console.log("To remove.....  " + recordpath);
        firebase.database().ref(recordpath).remove();
    }

    if (data.length==0) return(<></>);

    return(
        <div className='flex-column grey_frame'>
            <h1>{siteName}</h1>
            <div className='flex-row'>
            {
            data.map(d=>{
                let startDate = toDateFormat(d.Value.start);


                let classVal = "highlight_hover ";
                if (startDate == "") classVal += "alert_monitoring";
                return (
                    <div className={classVal}>
                    <div>{d.Name} {startDate}</div>
                    <div>
                        {
                        Object.keys(d.Value).map(k=>{
                            return(
                                <div 
                                className='flex-row'

                                >
                                    {
                                    (k=="Care Delivered") 
                                    ? <div>Care Delivered</div>
                                    :   <>
                                            <div><b>{k}:&nbsp;&nbsp;&nbsp;</b></div>
                                            <div>{d.Value[k]}</div>
                                        </>
                                    }
                                </div>
                            );
                        })
                        }
                        <button
                            onClick={()=>removeRecord(d.Name)}
                        >Remove Record</button>
                    </div>
                    </div>
                )
            })    
            }
            </div>
        </div>
    );
}
export default MultiPanelDashboardPage;