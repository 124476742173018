import React, {useEffect, useState, useRef} from "react";

const ChartCard = (props) => {
  
  return (
    <div className="chartsuite_card">
      <h3>{props.title}</h3>
      {props.children}
      <p>Last updated : 2 hours ago</p>
    </div>
  );

}

export default ChartCard;