import React, { useEffect } from 'react';
import axios from 'axios';
import IIWARI_CONNECTOR from './iiwari';

const LocationPage=()=>{


    useEffect(()=>{
        return;
        setTimeout(()=>{
            let frame = document.getElementById("iiwariframe");
           console.log(frame);
        }, 5000);
        
    },[]);

    return(
        <div>
            <iframe
                id='iiwariframe' 
                src="https://dash.iiwari.cloud/sites/018290c0-4a7b-9f03-18bb-64cba6e030a5?token=c75TVsesrqHlJbhBGoeULMeAqP1xI9MqIw1ebMzox0GnJSik918jHvEg3Sk6wsSyQGTzdQUAFIRoqF5s32&theme=arquella"
//                src='https://dash.iiwari.cloud/sites/01811f4e-6fbb-bf45-5313-359ff6f55576'
                height={600}
                width={1200}
                style={{
                    marginRight:"10px", 
                    marginTop:"10px",
                }}
                >
            </iframe>        
            <LocationEventRunner></LocationEventRunner>
            <div id="newtest"></div>
        </div>
    );

}


const LocationEventRunner=()=>{

    let ii;

    useEffect(()=>{
        console.log("setup");
        ii = new IIWARI_CONNECTOR(); 
        setupIiwari();

    },[]);

    async function setupIiwari() {
        
        let loggin;
        await ii.postAPIData('users/login', {}).then((res)=>{ 
          loggin = res 
        }).catch(e=>{reportError(e)});        
        
        console.log({loggin});

        const token = {'token': loggin.data.token};
        if ((loggin.data==null) || (loggin.data.user==null)) return;
    
        let sites;
        await ii.getAPIData('sites', token).then((res)=>{ sites = res }).catch(e=>reportError(e));
        if ((sites==null) || (sites.data==null)) return;
        console.log("SSSSSSSSS", sites.data);

        

        let siteStream = ii.getAPIData("sites/018290c0-4a7b-9f03-18bb-64cba6e030a5/locations/stream",token)
        .then((res)=>{ 
            console.log("Stream");
            console.log(res); 
        })
        .catch(e=>reportError(e));


/*
        let siteStream = await ii.getAPIData("sites/018290c0-4a7b-9f03-18bb-64cba6e030a5/events/stream", token)
        .then((res)=>{ 
            console.log("Stream");
            console.log(res); 
        })
        .catch(e=>reportError(e));
*/


    } 

    function reportError(err){ console.log("ERRRR", err); }

    /*
    var locationDB = axios.create({
        baseURL: 'https://dash.iiwari.cloud/api/v1/',
        timeout: 1000,
        //headers: {'X-Custom-Header': 'foobar'}
    });

    function login(){
        //locationDB.
    }

    useEffect(()=>{
        
        login();
        

    },[]);
    */

    return(
    <>
        
    </>
    );

}




export default LocationPage;